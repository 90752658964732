import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'

import config from '../utils/siteConfig'
import { getFrenchDate } from '../utils/dateHelpers'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Post from '../components/Post'

import { getPostPath } from '../utils/helpers'

export default ({ data }) => {
  const { post, prev, next } = data

  const context = {
    prev,
    next,
  }

  const date = getFrenchDate(post.frontmatter.date, 'll')

  return (
    <Layout>
      <Helmet>
        <title>{`${post.frontmatter.title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO
        pagePath={getPostPath(post)}
        postNode={post}
        excerpt={post.excerpt}
        postSEO
      />
      <Post
        {...post}
        title={post.frontmatter.title}
        body={post.html}
        context={context}
        publishDate={date}
      />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $prev: String, $next: String) {
    post: markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 320)
      frontmatter {
        categories
        date
        description
        featured_image
        Thumbnail_Large
        slug
        tags
        title
        type
        url
        author
        cat
      }
    }
    prev: markdownRemark(id: { eq: $prev }) {
      frontmatter {
        date
        slug
        title
        type
        url
        cat
      }
    }
    next: markdownRemark(id: { eq: $next }) {
      frontmatter {
        date
        slug
        title
        type
        url
        cat
      }
    }
  }
`
